<template>
  <div>
    <div class="new--account--profile--form">
      <form action="" class="new--form" v-on:submit.prevent="checkForm">
        <div class="new--form__pd">
          <div class="new--item">
            <label for="email">{{ $t("register.register_email") }}</label>
            <input
              class="new--form__input"
              name="email"
              type="email"
              v-model="profileFrom.email"
            />
          </div>
          <div class="new--item">
            <label for="pw">{{ $t("register.register_password") }}</label>
            <input
              class="new--form__input"
              name="pw"
              type="password"
              v-model="profileFrom.password"
            />
          </div>
          <div class="new--item">
            <label for="cfpw">
              {{ $t("register.register_confirm_password") }}
            </label>
            <input
              class="new--form__input"
              name="cfpw"
              type="password"
              v-model="profileFrom.confirmPassword"
            />
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form--input--error" v-if="errors.errorMsg.length">
                <ul>
                  <li v-for="(error, index) in errors.errorMsg" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="new--submit btn--blk">
          {{ $t("register.register_register_password") }}
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { authHeader } from "@/helpers/authHeader";

export default {
  name: "newAccountForm",
  data() {
    return {
      errors: {
        email: false,
        password: false,
        confirmPassword: false,
        errorMsg: []
      },
      profileFrom: {
        email: "",
        password: "",
        confirmPassword: ""
      }
    };
  },

  methods: {
    ...mapActions("common", ["setUser", "setToken"]),

    async checkForm(e) {
      this.errors.errorMsg = [];

      if (this.profileFrom.email == "") {
        this.errors.email = true;
        this.errors.errorMsg.push("Email not correct.");
      } else {
        this.errors.email = false;
      }

      if (
        this.profileFrom.password == "" ||
        this.profileFrom.confirmPassword == ""
      ) {
        this.errors.password = true;
        this.errors.confirmPassword = true;
        this.errors.errorMsg.push("Password field is empty.");
      }

      if (this.profileFrom.password || this.profileFrom.confirmPassword) {
        if (this.profileFrom.password !== this.profileFrom.confirmPassword) {
          this.errors.password = true;
          this.errors.confirmPassword = true;
          this.errors.errorMsg.push("Password Must be Matching.");
        } else {
          this.errors.password = false;
          this.errors.confirmPassword = false;
        }
      }

      if (
        this.profileFrom.email !== "" &&
        this.profileFrom.password !== "" &&
        this.profileFrom.confirmPassword !== "" &&
        this.errors.email == false &&
        this.errors.password == false &&
        this.errors.confirmPassword == false
      ) {
        try {
          let user = await this.apiRegisterUser(this.profileFrom);

          if (!user) {
            this.errors.oldPassword = true;
            this.errors.errorMsg.push("old password not correct.");
            return;
          }
        } catch (error) {
          this.errors.errorMsg.push(error);
        }

        // this.$parent.editAccountProfileClosed();
        // this.profileFrom.oldPassword = "";
        // this.profileFrom.newPassword = "";
        // this.profileFrom.confirmNewPassword = "";
        // return;
      }
    },

    async apiRegisterUser(formData) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/registerUser/`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
          }
        );

        if (!response.ok) {
          const { Message } = await response.json();
          throw Message;
        }

        const { data } = await response.json();
        return data;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.close-btn {
  z-index: 100;
  top: -40px;
  right: 0;
}
.new--account--profile--form {
  height: 100%;
}
.form--wrapper {
  padding: 50px 30px;

  h2 {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 34px;
  }
  label {
    font-size: 18px;
  }
  input[type="text"] {
    display: block;
    width: 90%;
    padding-bottom: 5px;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: 22px;
    border-bottom: 1px solid #555;
    outline: none;
    &::placeholder {
      // color:
      font-size: 18px;
      @include respond-to(screen-md) {
        font-size: 22px;
      }
    }
    &.error {
      border-bottom-color: red;
    }
  }
}
.form--input {
  &--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @include respond-to(screen-md) {
      margin-bottom: 20px;
    }
    label {
      width: 10%;
    }

    .private--collection {
      position: relative;
      margin-top: 20px;
      .c-cb-wrap {
        // opacity: .4;
        line-height: 1;
        width: 100%;
        display: block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        & input {
          opacity: 0.4;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .checkmark {
            background-color: #e6e6e6;
            border: 1px solid black;
          }
          &:checked ~ .checkmark:after {
            background-color: #e6e6e6;
            border: 1px solid black;
            // opacity: 0;
          }
          &:checked {
            opacity: 1;
          }
        }
      }
      .checkmark {
        top: 50%;
        /* margin: auto; */
        transform: translateY(-50%);
        position: absolute;
        // top: 0;
        left: 0;
        height: 13px;
        width: 13px;
        background-color: rgb(0, 0, 0);
        border: 1px solid black;
        border-radius: 50%;
        &:after {
          content: "";
          position: absolute;
          display: none;
          // position: absolute;
          // left: 9px;
          // top: 5px;
          // width: 5px;
          // height: 10px;
          // border: solid white;
          // border-width: 0 3px 3px 0;
          // -webkit-transform: rotate(45deg);
          // -ms-transform: rotate(45deg);
          // transform: rotate(45deg);
        }
      }
    }
  }
  &--error {
    margin-bottom: 20px;
    ul {
      color: red;
      margin: 0;
      padding: 0;
    }
  }
}
.c-cb {
  justify-content: left;
}
.new--submit {
  width: 100%;
  text-transform: uppercase;
}

.new {
  &--form {
    width: 590px;
    &__input {
      display: block;
      width: 100%;
      border-bottom: 1px solid $color-black;
      padding: 10px 0 5px;
      font-size: 22px;
      margin-bottom: 40px;
    }
    &__pd {
      padding: 50px;
    }
  }
  &--item {
    label {
      font-size: 18px;
    }
  }
  &--submit {
    width: 100%;
  }
  .close-btn {
    z-index: 100;
    top: -40px;
    right: 0;
  }
}
</style>
